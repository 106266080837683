<template>
    <div class="content" v-if="error">
        <h3 class="title">An unexpected error occurred. <br />Please try again later</h3>
        <p>
            If the problem persists, please
            <a href="mailto:contact@zakchat.com">email us.</a>
        </p>
    </div>
    <div class="content" v-else-if="submitted">
        <h3 class="title">You have successfully been unsubscribed</h3>
    </div>
    <div class="content" v-else>
        <h2 class="title">Unsubscribe</h2>
        <p class="subtext">No longer wish to receive updates from ZAKchat?</p>
        <form @submit.prevent="handleUnsubscribe">
            <label class="label">
                <span class="label-text">Email Address</span>
                <input
                    type="email"
                    name="email"
                    placeholder="Email address*"
                    @input="clearEmailError"
                    v-model="email"
                />
                <div v-if="invalidEmail">
                    <span class="error">We couldn't find an account with that email.</span>
                </div>
            </label>
            <div class="button-layout">
                <button type="submit" :disabled="!email">Unsubscribe</button>
            </div>
            <p>
                If you have any issues, please
                <a href="mailto:contact@zakchat.com">email us.</a>
            </p>
        </form>
    </div>
</template>
<script>
import auth from "@/service/auth";
import MarketingApi from "@/api/marketing";

export default {
    data() {
        return {
            email: "",
            submitted: false,
            error: false,
            invalidEmail: false,
        };
    },

    mounted() {
        const user = auth.getUser();
        if (!user || !user.email) {
            return;
        }

        this.email = user.email;
    },
    methods: {
        clearEmailError() {
            this.invalidEmail = false;
        },
        async handleUnsubscribe() {
            try {
                await MarketingApi.unsubscribe(this.email);
                this.submitted = true;
            } catch (e) {
                if (e.code !== "NotExist") {
                    this.error = true;
                    return;
                }

                this.invalidEmail = true;
            }
        },
    },
};
</script>

<style lang="scss">
.title {
    color: $color-montecarlo;
}

.content {
    max-width: 560px;
    margin: auto;
    padding: 80px;
    background: $color-white;

    .label {
        display: grid;

        .label-text {
            margin-bottom: 15px;
            color: $color-montecarlo;
        }

        .error {
            color: $color-bittersweet;
        }
    }

    .button-layout {
        display: flex;
        justify-content: flex-end;
        margin: 30px 0 80px 0;

        & > button {
            font-family: Muli, sans-serif;
            font-size: 16px;
            text-transform: none;
            line-height: 1.19;
            color: #ffffff;
            box-shadow: 0 7px 14px 0 rgba(128, 209, 182, 0.5);
            background-image: linear-gradient(95deg, #b7eadc -52%, #7bd9ba 56%);
            border: none;
            transition: 500ms;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    p > a {
        color: $color-montecarlo;
    }

    .subtext {
        margin-bottom: 30px;
        font-size: 18px;
    }
}
</style>
