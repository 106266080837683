const unsubscribe = async (email) => {
    const res = await fetch("/api/marketing/unsubscribe", {
        method: "POST",
        body: JSON.stringify({
            email,
        }),
    });

    if (res.status === 400) {
        const err = new Error("User could not be updated");
        err.code = "NotExist";
        throw err;
    }

    if (res.status !== 200) {
        throw new Error("Unexpected status " + res.status);
    }

    return "";
};

export default {
    unsubscribe,
};
